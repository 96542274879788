import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { BaseRootState, BaseDispatch } from "redux/store"

const CloseSideBar: React.FC = ({ children }) => {
  const dispatch = useDispatch<BaseDispatch>()
  const { isOpen } = useSelector((state: BaseRootState) => state.sidebar)
  React.useEffect(() => {
    if (isOpen) dispatch.sidebar.setIsOpen(false)
  }, [])
  return <>{children}</>
}

export default CloseSideBar
