import React from "react"
import { Spinner, Flex, Box } from "@chakra-ui/core"
import { graphql } from "gatsby"

import CloseSideBar from "../components/CloseSideBar"
import BicycleProductList from "../views/BicycleProductList"
import SearchWrapper from "../components/Search/SearchWrapper"
import { SearchResultsQuery } from "../../graphql-types"
import { StringParam, useQueryParam } from "use-query-params"

export const query = graphql`
  query SearchResults {
    colors: allContentfulColorFamily {
      edges {
        node {
          internalTitle
          name
          hexColor
          product_color {
            internalTitle
          }
        }
      }
    }
  }
`

const SearchResults: React.FC<{ data: SearchResultsQuery }> = ({ data }) => {
  const [searchText] = useQueryParam("searchText", StringParam)

  if (!searchText)
    return (
      <Box textAlign="center" padding="1rem">
        No search text was given
      </Box>
    )
  return (
    <SearchWrapper searchText={searchText} shouldFetchSuggestion={false}>
      {({ isFetching, results }) => {
        if (isFetching) {
          return (
            <CloseSideBar>
              <Flex
                w="100%"
                h="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Spinner color="primary" size="xl" />
              </Flex>
            </CloseSideBar>
          )
        }
        return (
          <BicycleProductList
            data={{ bikes: results, colors: data.colors }}
            pageContext={{
              pageTitle: `${results.length} Results for "${searchText}"`,
            }}
          />
        )
      }}
    </SearchWrapper>
  )
}

export default SearchResults
